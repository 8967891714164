import React from 'react'

import Logo from '../assets/images/pg_logo.png';


export const Footer = () => {
  return (
		<div className="footer">
			<div className="container">
				<div className="row justify-content-space-between align-items-center">
					<div className="col-12 col-md-3">
						<img
							src={Logo}
							alt="Prestige Gifting Logo"
							className="footer-logo"
						/>
					</div>
					<div className="col-12 col-md-6 copyright-information">
						© Copyright 2006 - {new Date().getFullYear()} Prestige Gifting Ltd. | All Rights Reserved
						<br />
						Company Registration 5778485 | VAT Registration 124115363
					</div>
				</div>
			</div>
		</div>
	);
}
