import React from 'react'

import Haute from '../assets/images/haute.png';
import PrestigeFlowers from '../assets/images/pf-logo.png';
import PrestigeHampers from '../assets/images/ph-logo.png';
import BmFlowers from '../assets/images/bmflowers.png';
import NationalGallery from '../assets/images/ng-logo.svg';
import CRUK from '../assets/images/cruk.png';


export const BrandList = () => {
  return (
		<section className="brand-list">
			<div className="container">
				<h1>All Things Gifting, All In One Place</h1>
				<p>Proud to have produced some of the largest gifting websites in the world.</p>
				<div className="brands">
					<img src={PrestigeFlowers} />
					<img src={PrestigeHampers} />
					<img src={Haute} />
					<img
						className="white-logo"
						src={NationalGallery}
					/>
					<img src={BmFlowers} />
					<img src={CRUK} />
				</div>
			</div>
		</section>
	);
}
