import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Header, Footer } from './components';
import { Home, AboutUs } from "./pages";

const baseURL = document.getElementsByTagName("base")[0].getAttribute("href");

function App() {
	return (
		<BrowserRouter basename={baseURL}>
			<div className="main-container">
				<Header />
				<Routes>
					<Route
						path="/"
						element={<Home />}
					/>
					<Route
						path="/about-us"
						element={<AboutUs />}
					/>
				</Routes>
				<Footer />
			</div>
		</BrowserRouter>
	);
}

export default App;
