import React from 'react'
import { Hero, JobTypes, BrandList } from "../components";

export const Home = () => {
  return (
		<>
			<Hero />
			<JobTypes />
			<BrandList />
		</>
	);
}
