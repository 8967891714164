import React, {useState, useEffect} from 'react'
import Kitchen from '../assets/images/kitchen.jpg';
import Entrance from '../assets/images/entrance.jpg';
import WeHaveFun from '../assets/images/fun.jpg';
import GreenHouse from '../assets/images/greenhouse.jpg';


export const Hero = () => {

    
    const [activeUsp, setActiveUsp] = useState(0);
    const [activeImage, setActiveImage] = useState(0);

    const duration = 8000;

    const usps = [
        "Online Florist",
        "Hamper Supplier",
        "Corporate Gifting"
    ];

    const hero_images = [
        Entrance,
        WeHaveFun,
        GreenHouse,
        Kitchen,
    ];

    const uspCount = usps.length;
    const imageCount = hero_images.length;

    useEffect(() => {

        console.log("There is " + uspCount + " usps");

        setInterval(() => {
            setActiveUsp(prev => {
                return (prev < uspCount - 1) ? prev + 1 : 0;                
            });

            setActiveImage(prev => {
                return (prev < imageCount - 1) ? prev + 1 : 0;
            });
        }, duration);
    }, []);
    

    return (
        <section className="hero-section">
            <div className="container">
                <div className="row justify-content-space-between align-items-center">
                    <div className="col-12 col-md-5">
                        <div className="usps">
                            <h1>
                                One of the <br></br>UK's leading
                            </h1>
                            <div className="usp-claim">
                                <div className="usp-wrapper" style={{
                                    "--active-usp": activeUsp,
                                    "--animation-duration": duration + "ms",
                                }}>
                                    {
                                        usps.map((usp, index) => (
                                            <div className="usp" key={index}>
                                                <div className="tagline-text">
                                                    {usp}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <h1>
                                Companies
                            </h1>
                        </div>
                        <p className="introductory-paragraph">
                            We are a leading online florist and hamper supplier, with a wide range of products and services to suit all occasions. 
                        </p>

                        {/* <button className="btn">
                            Learn More <FontAwesomeIcon icon={faArrowRight} />
                        </button> */}

                        <hr />

                        <div className="stats">
                            <div className="stat">
                                <strong>350K+</strong>
                                People Trust Us
                            </div>
                            <div className="stat">
                                <strong>9M+</strong>
                                Products Sold
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-7 images-container">

                        <div className="hero-images">
                            {
                                hero_images.map((image, index) => (
                                    <img className={`hero-image ${activeImage == index ? 'active' : ''}`} key={index} src={image} />
                                ))
                            }
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}
