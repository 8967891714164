import React from 'react'
import { Link } from 'react-router-dom';

import Logo from '../assets/images/pg_logo.png';

export const Header = () => {
    return (
        <header id="main-header" className="main-header">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-3">
                        <div className="logo-container">
                            <Link to="/">
                                <img src={Logo} alt="Prestige Gifting Logo" />
                            </Link>
                        </div>
                    </div>
                    {/* <div className="col-fluid">
                        <nav className="navigation">
                            <Link to="/">
                                Home
                            </Link>

                            <Link to="/about-us">
                                About Us
                            </Link>
                        </nav>
                    </div> */}
                </div>
            </div>
        </header>
    )
}
