import React from 'react'


import Code from '../assets/images/Development.png';
import Contract from '../assets/images/Business.png';
import Stats from '../assets/images/Marketing.png';


export const JobTypes = () => {
  return (
		<section className="job-types">
			<div className="container">
				<div
					className="row justify-content-center justify-content-md-space-between"
					style={{
						"--gap": "15px"
					}}
				>
					<div className="col-12 col-md-4 job-type">
						<h3>For Developers</h3>
						<h2>Keep Everything Running</h2>
						<img
							src={Code}
							alt="Code"
						/>

						<div className="points">
							<div className="point">Front End</div>
							<div className="point">Back End</div>
							<div className="point">DevOps</div>
							<div className="point">IT Technician</div>
						</div>
					</div>
					<div className="col-12 col-md-4 job-type">
						<h3>For Marketers</h3>
						<h2>Shout It From The Rooftops</h2>
						<img
							src={Stats}
							alt="Stats"
						/>

						<div className="points">
							<div className="point">Merchandising</div>
							<div className="point">SEO</div>
							<div className="point">Photography</div>
							<div className="point">Brand Management</div>
						</div>
					</div>
					<div className="col-12 col-md-4 job-type">
						<h3>For Professionals</h3>
						<h2>Piece Everything Together</h2>
						<img
							src={Contract}
							alt="Contract"
						/>

						<div className="points">
							<div className="point">Accounts</div>
							<div className="point">Sales</div>
							<div className="point">Customer Service</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
